@import 'normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    //   font-size: 62.5%;
}

body {
    text-rendering: optimizeSpeed;
    background-color: #f5f6f7;
}

button,
input,
[tabindex] {
    outline: none;
    border: none;
}

a[href] {
    text-decoration: none;
}

html *::-webkit-scrollbar {
    border-radius: 0;
    width: 8px;
}

html *::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(22, 24, 35, 0.06);
}

html *::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
}
